var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-range" },
    [
      _c("div", { staticClass: "filter-range__select" }, [
        _c("span", [_vm._v("от")]),
        _c("input", {
          ref: "from",
          attrs: { type: "text" },
          domProps: { value: _vm._f("formatFrom")(_vm.value.from) },
          on: {
            blur: function ($event) {
              return _vm.rangeFrom(_vm.inputFrom)
            },
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.rangeFrom(_vm.inputFrom)
            },
            input: _vm.setValueFrom,
          },
        }),
      ]),
      _c("div", { staticClass: "filter-range__div" }),
      _c("div", { staticClass: "filter-range__select" }, [
        _c("span", [_vm._v("до")]),
        _c("input", {
          ref: "to",
          attrs: { type: "text" },
          domProps: { value: _vm._f("formatTo")(_vm.value.to) },
          on: {
            blur: function ($event) {
              return _vm.rangeTo(_vm.inputTo)
            },
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.rangeTo(_vm.inputTo)
            },
            input: _vm.setValueTo,
          },
        }),
      ]),
      _c("vue-slider", {
        staticClass: "filter-range__slider",
        attrs: {
          lazy: "",
          silent: "",
          disabled: _vm.sliderFrom === _vm.sliderTo,
          interval: _vm.step,
          min: _vm.sliderFrom,
          max: _vm.sliderTo,
        },
        on: { change: _vm.change },
        model: {
          value: _vm.sliderValue,
          callback: function ($$v) {
            _vm.sliderValue = $$v
          },
          expression: "sliderValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }