var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "banner-card" }, [
    _c("div", { staticClass: "banner-card__left" }, [
      _c("div", { staticClass: "banner-card__body" }, [
        _c("div", {
          staticClass: "banner-card__title",
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
        _c("div", { staticClass: "banner-card__text" }, [
          _vm.text
            ? _c("div", {
                staticClass: "banner-card__text-main",
                domProps: { innerHTML: _vm._s(_vm.text) },
              })
            : _vm._e(),
          _vm.agreementText
            ? _c("div", {
                staticClass: "banner-card__text-addit",
                domProps: { innerHTML: _vm._s(_vm.agreementText) },
              })
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "banner-card__footer" },
        [
          _c(
            "ui-button",
            {
              attrs: {
                "data-popup-with-chats": "",
                "data-popup-with-chats-title": _vm.buttonTitle,
                "data-popup-with-chats-source-id": "apartmentsPromo",
              },
            },
            [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "banner-card__right" }, [
      _c("img", {
        staticClass: "banner-card__image",
        attrs: { src: _vm.image },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }