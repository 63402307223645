<template>
  <div class="filter-range">
    <div class="filter-range__select">
      <span>от</span>
      <input
        ref="from"
        :value="value.from | formatFrom"
        type="text"
        @blur="rangeFrom(inputFrom)"
        @keypress.enter="rangeFrom(inputFrom)"
        @input="setValueFrom"
      />
    </div>
    <div class="filter-range__div" />
    <div class="filter-range__select">
      <span>до</span>
      <input
        ref="to"
        :value="value.to | formatTo"
        type="text"
        @blur="rangeTo(inputTo)"
        @keypress.enter="rangeTo(inputTo)"
        @input="setValueTo"
      />
    </div>
    <vue-slider
      v-model="sliderValue"
      class="filter-range__slider"
      lazy
      silent
      :disabled="sliderFrom === sliderTo"
      :interval="step"
      :min="sliderFrom"
      :max="sliderTo"
      @change="change"
    />
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "FilterRange",
  components: {
    VueSlider,
  },
  filters: {
    formatFrom(value) {
      return value.toLocaleString("ru");
    },
    formatTo(value) {
      return value.toLocaleString("ru");
    },
  },
  props: {
    value: {},
    step: {
      default: 1,
      type: Number,
    },
    from: {
      required: true,
      default: 1,
    },
    to: {
      required: true,
      default: 100,
    },
  },
  data: () => {
    return {
      sliderValue: [0, 1],
      inputFrom: 0,
      inputTo: 0,
    };
  },
  computed: {
    sliderFrom() {
      return parseInt(this.from) || 0;
    },
    sliderTo() {
      return parseInt(this.to) || 1;
    },
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        this.rangeFrom(this.value.from);
        this.rangeTo(this.value.to);
      },
    },
    from() {
      this.rangeFrom(this.value.from);
      this.rangeTo(this.value.to);
    },
    to() {
      this.rangeFrom(this.value.from);
      this.rangeTo(this.value.to);
    },
    sliderValue() {
      this.rangeFrom(this.sliderValue[0], false);
      this.rangeTo(this.sliderValue[1], false);
    },
  },
  created() {
    this.sliderValue = [this.value.from, this.value.to];
    this.rangeFrom(this.value.from);
    this.rangeTo(this.value.to);
  },
  mounted() {
    setTimeout(() => {
      this.inputFrom = this.getTrimValue(
        this.$refs.from.value.replaceAll(" ", "")
      );
      this.inputTo = this.getTrimValue(this.$refs.to.value.replaceAll(" ", ""));
    }, 1000);
  },
  methods: {
    change() {
      this.$emit("change");
    },
    getTrimValue(value) {
      return parseInt(value.trim().replace(/\xA0/g, "").replaceAll(" ", ""));
    },
    setValueFrom() {
      this.$emit("change");
      this.inputFrom = this.getTrimValue(
        this.$refs.from.value.replaceAll(" ", "")
      );
    },
    setValueTo() {
      this.$emit("change");
      this.inputTo = this.getTrimValue(this.$refs.to.value.replaceAll(" ", ""));
    },
    rangeFrom(newVal, updateSlider = true) {
      this.inputFrom = newVal;
      let val = parseInt(newVal);
      if (isNaN(val)) {
        if (this.value.from !== this.sliderFrom) {
          this.$emit("input", {
            ...this.value,
            from: this.sliderFrom,
          });
        }

        if (updateSlider) {
          this.sliderValue = [this.value.from, this.value.to];
        }

        return;
      }

      if (val < this.from) val = this.sliderFrom;
      if (val > this.to) val = this.sliderTo;

      if (this.value.from !== parseInt(val)) {
        this.$emit("input", {
          ...this.value,
          from: parseInt(val),
        });
      }

      if (updateSlider) {
        this.sliderValue = [this.value.from, this.value.to];
      }
    },

    rangeTo(newVal, updateSlider = true) {
      this.inputTo = newVal;
      let val = parseInt(newVal);
      if (isNaN(val)) {
        if (this.value.to !== this.sliderTo) {
          this.$emit("input", {
            ...this.value,
            to: this.sliderTo,
          });
        }

        if (updateSlider) {
          this.sliderValue = [this.value.from, this.value.to];
        }

        return;
      }

      if (val < this.from) val = this.sliderFrom;
      if (val > this.to) val = this.sliderTo;

      if (this.value.to !== parseInt(val)) {
        this.$emit("input", {
          ...this.value,
          to: parseInt(val),
        });
      }

      if (updateSlider) {
        this.sliderValue = [this.value.from, this.value.to];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filter-range {
  height: 36px;
  background: #f5f5f5;
  display: flex;
  border-radius: 3px;
  /*border: 1px solid #cbd0dd;*/
  transition: all 0.3s ease-out;
  align-items: center;
  padding: 0 15px;
  position: relative;

  &:focus {
    /*border: 1px solid #333333;*/
  }

  &:hover {
    /*border: 1px solid #333333;*/
    background: #efefef;
  }

  &__slider {
    position: absolute;
    width: 100% !important;
    bottom: -7px;
    left: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &__div {
    border-left: 1px solid rgb(220, 220, 230);
    margin: 0px 16px;
    height: 15px;
  }

  &__select {
    display: flex;
    align-items: center;
    width: 100%;

    span {
      font-size: 14px;
      line-height: 1;
      font-weight: 300;
      color: #cbd0dd;
    }

    input {
      border: 0;
      font-size: 14px;
      outline: none;
      width: 100%;
      font-weight: 100;
      background: transparent;
      padding: 0;
      margin-left: 10px;
      color: #494949;
    }
  }
}
</style>
