<template>
  <div class="ms-checkbox-switch">
    <label>
      <input v-model="check" :required="required" type="checkbox" />
      <div
        class="ms-checkbox-switch__container"
        :class="{ 'ms-checkbox-switch__container--active': check }"
      >
        <div
          class="ms-checkbox-switch__point"
          :class="{ 'ms-checkbox-switch__point--active': check }"
        />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxSwitch",
  props: {
    value: {},
    required: {
      type: Boolean,
      default: false,
    },
    activeBackground: {
      type: String,
      default: "#FF5722",
    },
  },
  data: () => {
    return {
      check: false,
    };
  },
  watch: {
    check() {
      this.$emit("input", this.check);
    },
    value() {
      this.check = this.value;
    },
  },
  created() {
    this.check = this.value;
  },
};
</script>

<style lang="scss" scoped>
.ms-checkbox-switch {
  position: relative;
  label {
    cursor: pointer;
  }
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    border-radius: 0;
    position: absolute;
    top: 0;
  }
  &__container {
    background: #787e87;
    border-radius: 10px;
    width: 36px;
    height: 20px;
    position: relative;
    transition: background-color 0.2s ease;
    &--active {
      background-color: #ff5722;
    }
  }
  &__point {
    position: absolute;
    background: #ffffff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    transition: left 0.2s ease;
    &--active {
      left: calc(100% - 18px);
    }
  }
}
</style>
