<template>
  <div class="banner-card">
    <div class="banner-card__left">
      <div class="banner-card__body">
        <div class="banner-card__title" v-html="title" />
        <div class="banner-card__text">
          <div v-if="text" class="banner-card__text-main" v-html="text" />
          <div
            v-if="agreementText"
            class="banner-card__text-addit"
            v-html="agreementText"
          />
        </div>
      </div>
      <div class="banner-card__footer">
        <ui-button
          data-popup-with-chats
          :data-popup-with-chats-title="buttonTitle"
          data-popup-with-chats-source-id="apartmentsPromo"
        >
          {{ buttonTitle }}
        </ui-button>
      </div>
    </div>
    <div class="banner-card__right">
      <img class="banner-card__image" :src="image" />
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UIButton";

export default {
  name: "BannerCard",
  components: {
    UiButton,
  },
  props: {
    modalTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    agreementText: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    clickFunc: {
      type: Function,
      required: false,
    },
  },
  methods: {
    handleClick() {
      if (this.clickFunc) {
        this.clickFunc();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner-card {
  background: #000000;
  display: flex;
  justify-content: space-between;
  min-height: 207px;

  @media screen and (min-width: $screen-sm) {
    min-height: 276px;
  }

  &__left {
    display: flex;
    padding: 25px 15px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: $screen-sm) {
      padding: 50px 40px;
      width: calc(50% - 80px);
    }
    @media screen and (min-width: $screen-md) {
      width: calc(50% - -80px);
    }
    @media screen and (min-width: $screen-lg) {
      width: calc(50% - 80px);
    }
  }
  &__right {
    display: none;

    @media screen and (min-width: $screen-sm) {
      display: block;
    }
  }
  &__title {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 105%;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 15px;
    @media screen and (min-width: 375px) {
      font-size: 27px;
    }
    @media screen and (min-width: $screen-xs) {
      font-size: 30px;
    }
    @media screen and (min-width: $screen-md) {
      font-size: 34px;
    }
  }
  &__text {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 132.5%;
    margin-bottom: 5px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);

    @media screen and (min-width: $screen-sm) {
      font-size: 19px;
    }

    &-addit {
      margin-top: 15px;

      @media (min-width: $screen-sm) {
        margin-top: 20px;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
  }
}
</style>
