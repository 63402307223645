var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ms-checkbox-switch" }, [
    _c("label", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.check,
            expression: "check",
          },
        ],
        attrs: { required: _vm.required, type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.check)
            ? _vm._i(_vm.check, null) > -1
            : _vm.check,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.check,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.check = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.check = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.check = $$c
            }
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "ms-checkbox-switch__container",
          class: { "ms-checkbox-switch__container--active": _vm.check },
        },
        [
          _c("div", {
            staticClass: "ms-checkbox-switch__point",
            class: { "ms-checkbox-switch__point--active": _vm.check },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }